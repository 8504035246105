import React, { Component } from 'react';
import { Form, Row, Col, Label, Input, Button } from 'reactstrap';
import { request } from './request.js';
import BarcodeReader from 'react-barcode-reader';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/dist/jquery.min.js';
import './App.css';

class App extends Component 
{

	constructor(props)
	{
		super(props)
		this.state = {
			ficha :
			{
				id : '',
				folio : '',
				nombre : '',
				apellido_paterno : '',
				apellido_materno : '',
				confirmacion : '',
				imprimir : '',
				entregado : false,
			},
			codigo: '',
			encontrado : 0,
			limpiar : 0,
		}
		//this.handleScan = this.handleScan.bind(this);
		this.entregar_guia = this.entregar_guia.bind(this);
		this.devolver_guia = this.devolver_guia.bind(this);
	}

	// handleScan(data)
	// {
	// 	var { ficha } = this.state;
	// 	let temp = this;

	// 	request.get(`/Codigo/${data}`)
	// 	.then(function(response)
	// 	{
	// 		if(response.status === 200)
	// 		{
	// 			if(response.data)
	// 			{
	// 				ficha = response.data;
	// 				ficha.entregado=parseInt(ficha.entregado,10);
	// 				if(ficha.entregado===0) ficha.entregado = false;
	// 				else ficha.entregado = true;
	// 				temp.setState({ ficha : ficha });
	// 			}
	// 		}
	// 	})
	// 	.catch(error => {} );

	// 	this.setState({ ficha : ficha });
	// }

	buscarFicha=(event)=>
	{
		var { ficha, encontrado, limpiar } = this.state;
		let temp = this;
		limpiar = 1;

		request.get(`/Codigo2/${ficha.id}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha = response.data;
					ficha.entregado=parseInt(ficha.entregado,10);
					if(ficha.entregado===0) ficha.entregado = false;
					else ficha.entregado = true;
					encontrado = 1;
					temp.setState({ ficha : ficha, encontrado : encontrado });
				}
				else
				{
					encontrado = 0;
					temp.setState({ encontrado : encontrado });
				}
			}
		})
		.catch(error => {} );

		this.setState({ ficha : ficha, limpiar : limpiar });
	}

	limpiar=()=>
	{
		var { ficha, encontrado, limpiar } = this.state;			
		
		ficha.id = '';
		ficha.folio = '';
		ficha.nombre = '';
		ficha.apellido_paterno = '';
		ficha.apellido_materno = '';
		ficha.confirmacion = '';
		ficha.entregado = false;
		encontrado = 0;
		limpiar = 0;

		this.setState({ ficha : ficha, encontrado : encontrado, limpiar : limpiar });
	}


	handleInputChange=(event)=>
	{
		const value = event.target.value;
		var { ficha } = this.state;

		ficha['id'] = value;
	
		this.setState({ ficha : ficha });
	}

	// handleError(err)
	// {
	// 	console.error(err);
	// }

	entregar_guia()
	{
		var { ficha } = this.state;
		let temp = this;

		request.get(`/Entregar/${ficha.confirmacion}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha = response.data;
					temp.setState({ ficha : ficha });
				}
			}
		})
		.catch(error => {} );

		this.setState({ ficha : ficha });
	}

	devolver_guia()
	{
		var { ficha } = this.state;
		let temp = this;

		request.get(`/Devolver/${ficha.confirmacion}`)
		.then(function(response)
		{
			if(response.status === 200)
			{
				if(response.data)
				{
					ficha = response.data;
					temp.setState({ ficha : ficha });
				}
			}
		})
		.catch(error => {} );

		this.setState({ ficha : ficha });
	}

	render()
	{
		let { ficha, encontrado, limpiar } = this.state;

		return (
			<div className="back">
				<div className="main container text-center">
					<Row className="justify-content-around">
						<Col className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
							<div className="loginForm">
								<Label className="text-center"> <h3> Control de Guias </h3> </Label>
								<Form>
									<Row>
										<Col>
											<BarcodeReader onError={this.handleError} onScan={this.handleScan} />						
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="folio"> No. De folio (SEP):
												<Input className="texto inputs" type="text" name="folio" value={ ficha.folio } disabled />
											</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="ficha"> No. De Ficha (CBTIS):
												<Input className="texto inputs" type="text" name="id" value={ ficha.id } onChange={this.handleInputChange} />
												{
													limpiar === 0 ?
														<Button className="mt-2 text-center texto" onClick={this.buscarFicha}> BUSCAR </Button>
													:
														<Button className="mt-2 text-center texto" onClick={this.limpiar}> LIMPIAR </Button>
												}
											</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="nombre"> Nombre:
												<Input className="texto inputs cap" type="text" name="nombre" value={ ficha.nombre } disabled />
											</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="apellido_paterno"> Apellido Paterno:
												<Input className="texto inputs cap" type="text" name="apellido_paterno" value={ ficha.apellido_paterno } disabled />
											</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="apellido_materno"> Apellido Materno:
												<Input className="texto inputs cap" type="text" name="apellido_materno" value={ ficha.apellido_materno } disabled />
											</Label>
										</Col>
									</Row>
									<Row>
										<Col>
											<Label className="text-center" for="entregado"> Guia:
											{
												encontrado === 0 ? 
													<Input className="texto inputs" type="text" name="entregado" value="" disabled />
												:
													<Input className="texto inputs" type="text" name="entregado" value={ ficha.entregado ? "Entregada" : "Sin Entregar" } disabled />	
											}
												</Label>
										</Col>
									</Row>
									{
										encontrado === 0 ?
											''
										:
											parseInt(ficha.imprimir,10)===0 ?
												<Row className="pt-2">
													<Col className="espacio text-center">	
														<Button className="boton" color="warning" size="lg"> Proceso Incompleto </Button>
													</Col>
												</Row>
											:
												""
									}
									<Row className="pt-1">
										<Col className="espacio text-center">
										{
											encontrado === 0 ?
												''
											:
												ficha.entregado ? 
													<Button className="boton" color="warning" size="lg" onClick={this.devolver_guia}> CANCELAR </Button>
												:
													<Button className="boton" color="success" size="lg" onClick={this.entregar_guia}> ENTREGAR </Button>
										}
										</Col>
									</Row>
								</Form>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default App;